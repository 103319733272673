<template>
  <div class="l-page">
    <Header />

    <Loading v-show="loading"></Loading>

    <router-view></router-view>

    <Footer />
  </div>
</template>

<style lang="scss">
body {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium",
    "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #2c3e50;
}
a {
  &:link {
    color: #333;
    text-decoration: none;
  }
  &:visited {
    color: #333;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}
img {
  width: 100%;
  vertical-align: top;
}
</style>

<script>
import "normalize.css";
import Header from "./components/header/Header.vue";
import Footer from "./components/footer/Footer.vue";
import Loading from "./components/Loading.vue";

export default {
  methods: {
    createTitleDesc: function (routeInstance) {
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title;
        document.title = setTitle;
      } else {
        document.title = "";
      }

      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc;
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "description is not set");
      }
    },
  },
  mounted: function () {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
    setTimeout(() => {
      this.loading = false;
    }, 2600);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Header,
    Footer,
    Loading,
  },
};
</script>
