import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag-next";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTwitter);

var app = createApp(App);
app.use(router);
app.use(VueGtag, {
  property: {
    id: "UA-210392121-1",
    router,
  },
});
app.component("fa", FontAwesomeIcon);
app.mount("#app");
