<template>
  <div class="loading">
    <div class="loading__img"></div>
  </div>
</template>

<style scoped lang="scss">
.loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #d1e4dd;
  animation: loading 1s linear 1s forwards;
}

.loading__img {
  background: url("../assets/images/loading.png") center no-repeat;
  background-size: 20%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 7;
  animation: imgAnimation 0.1s linear 0.5s forwards;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes imgAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-10deg);
  }
}
</style>
