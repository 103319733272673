import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "三重とこわかソフトテニス大会2021",
      desc: "三重とこわか国体の代替大会 公式サイトです！",
    },
  },
  {
    path: "/parking",
    name: "Parking",
    component: () => import("../views/Parking.vue"),
    meta: {
      title: "駐車場情報｜三重とこわかソフトテニス大会2021",
      desc: "三重とこわか国体の代替大会 公式サイトです！",
    },
  },
  {
    path: "/tournament",
    name: "Tournament",
    component: () => import("../views/Tournament.vue"),
    meta: {
      title: "組み合わせ情報｜三重とこわかソフトテニス大会2021",
      desc: "三重とこわか国体の代替大会 公式サイトです！",
    },
  },
  {
    path: "/practice",
    name: "Practice",
    component: () => import("../views/Practice.vue"),
    meta: {
      title: "練習割当情報｜三重とこわかソフトテニス大会2021",
      desc: "三重とこわか国体の代替大会 公式サイトです！",
    },
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

trackRouter(router);

export default router;
