<template>
  <header class="l-header" :class="{ '-scroll': scrollY > mvHeight }">
    <div class="l-header__content">
      <div class="l-header__logo">
        <router-link to="/">
          <h1>三重とこわかソフトテニス大会2021</h1>
        </router-link>
      </div>

      <nav
        class="l-header__nav"
        :class="{ 'is-open': isOpen }"
        @click="hamburger()"
      >
        <ul>
          <li class="l-header__item"><router-link to="/">Home</router-link></li>
          <li class="l-header__item">
            <a href="https://twitter.com/tokowaka_st" target="_blank">
              <fa :icon="{ prefix: 'fab', iconName: 'twitter' }" />
            </a>
          </li>
        </ul>
      </nav>

      <div
        class="c-hamburger"
        :class="{ 'is-open': isOpen }"
        @click="hamburger()"
      >
        <span class="c-hamburger__line"></span>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.l-header {
  position: fixed;
  width: 100%;

  a {
    color: #fff;
  }

  &.-scroll {
    a {
      color: #2c3e50;
    }
    .c-hamburger {
      .c-hamburger__line {
        background-color: #2c3e50;

        &:before,
        &:after {
          background-color: #2c3e50;
        }
      }
      &.is-open {
        .c-hamburger__line {
          background-color: transparent;
        }
      }
    }
  }
}
.l-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 40px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
}
.l-header__logo {
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.l-header__nav {
  ul {
    list-style: none;
    display: flex;

    @media screen and (max-width: 768px) {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(30px);
    height: 100vh;
    left: 0;
    display: none;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease;
    width: 100%;
  }
  &.is-open {
    display: block;

    a {
      color: #2c3e50;
    }
  }
}
.l-header__item {
  margin-left: 20px;

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-left: 0;
  }

  & + .l-header__item {
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
}

.c-hamburger {
  display: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
  }

  &.is-open {
    .c-hamburger__line {
      background-color: transparent;

      &:before,
      &:after {
        top: 0px;
        background-color: #2c3e50;
      }
      &:before {
        transform: translateX(-50%) rotate(45deg);
      }
      &:after {
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }
}
.c-hamburger__line {
  background-color: #fff;
  border-radius: 10px;
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;

  &:before {
    content: "";
    background-color: #fff;
    border-radius: 10px;
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    width: 20px;
    bottom: 6px;
    transform: translateX(-50%);
    transition: transform 0.3s ease, top 0.3s ease;
  }
  &:after {
    content: "";
    background-color: #fff;
    border-radius: 10px;
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    width: 20px;
    top: 6px;
    transform: translateX(-50%);
    transition: transform 0.3s ease, bottom 0.3s ease;
  }
}
</style>

<script>
export default {
  data() {
    return {
      scrollY: 0,
      mvHeight: 0,
      isOpen: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
      this.mvHeight = document.getElementsByClassName("mv")[0].clientHeight;
    },
    hamburger() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
