<template>
  <footer class="l-footer">
    <div class="l-footer__content">
      <div class="l-footer__sponsor">
        <p class="sponsor__title">運営協力</p>
        <a href="http://softtennisschool.online/" target="_blank">
          <img src="../../assets/images/go/logo.png" />
          <span>ソフトテニスオンラインスクール</span>
        </a>
      </div>

      <small class="l-footer__copyright">
        Copyright © 2021 三重とこわかソフトテニス大会2021 All Rights Reserved.
      </small>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.l-footer {
  background: $footerColor;
  padding: 20px 0;
  text-align: center;
}
.l-footer__content {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 40px;
}
.l-footer__sponsor {
  img {
    max-width: 300px;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  span {
    display: block;
  }
}
.sponsor__title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.l-footer__copyright {
  display: block;
  margin-top: 20px;
}
</style>
